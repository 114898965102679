<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Grants, Contracted Research & Host Support
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              v-if="hasPermission('create grants')"
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              @click="$router.push({name: 'researcher.grants.create'})"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Research lines settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <GrantStats v-if="loggedUser.roles.includes('super-admin')" />
        <b-tabs :show="!loading">
          <b-tab
            :active="$route.query.tab == 1 || !$route.query.tab"
            @click="loadTab(1)"
          >
            <template #title>
              Grants & Contracted Research
            </template>
            <div class="row">
              <div
                v-if="!loading"
                class="col-12"
              >
                <CompleteTable
                  :sorted-field="sortedField"
                  :data="items"
                  :sort-mapping="sortMappingGrants"
                  :title="'grants and contracted research'"
                  :total="itemsTotal"
                  :fetch-path="'grants/fetch'"
                  :filter-path="'grants/filter'"
                  :export-path="'grants/export'"
                  @appliedFilters="appliedFilters($event)"
                >
                  <template v-slot="{field}">
                    <td
                      class="text-end"
                    >
                      <b-dropdown
                        variant="link"
                        no-caret
                        toggle-class="p-0"
                        right
                      >
                        <template #button-content>
                          <i data-feather="more-vertical" />
                        </template>
                        <div
                          class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                        >
                          <b-dropdown-item
                            v-if="loggedUser.roles.includes('super-admin')"
                            @click="$router.push({name: 'researcher.dashboard', params: { id: field.user.id }})"
                          >
                            <i
                              class="me-50"
                              data-feather="user"
                            /> View researcher profile
                          </b-dropdown-item>
                          <div
                            v-if="loggedUser.roles.includes('super-admin')"
                            class="dropdown-divider"
                          />
                          <b-dropdown-item @click="$router.push({name: 'researcher.grants.dashboard', params: { id: field.user.id }})">
                            <i
                              class="me-50"
                              data-feather="bar-chart-2"
                            /> Dashboard grants
                          </b-dropdown-item>
                          <div
                            v-if="hasPermission('update grants')"
                            class="dropdown-divider"
                          />
                          <b-dropdown-item
                            v-if="hasPermission('update grants')"
                            @click="$router.push({name: 'researcher.grants.edit', query: { tab: 1, actAs: $route.query.actAs }, params: { id: field.id }})"
                          >
                            <i
                              class="me-50"
                              data-feather="edit-3"
                            /> Edit
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="hasPermission('update grants')"
                            :href="$router.resolve({name: 'researcher.grants.edit', params: { id: field.id }, query: { tab: 1, actAs: $route.query.actAs }}).href"
                            target="_blank"
                          >
                            <i data-feather="external-link" />
                            Open in new tab
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="hasPermission('delete grants')"
                            @click="showDeleteModal(field.id)"
                          >
                            <i
                              class="me-50"
                              data-feather="trash-2"
                            /> Delete <span class="float-end"><i data-feather="lock" /></span>
                          </b-dropdown-item>
                        </div>
                      </b-dropdown>
                      <a
                        href=""
                        class="btn btn-icon btn-light-secondary d-block d-sm-none"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasMenu"
                        aria-controls="offcanvasBottom"
                      ><i
                        data-feather="more-vertical"
                      /></a>
                    </td>
                  </template>
                  <template #customfilters>
                    <template
                      v-if="hasAdminPermission('view grants')"
                    >
                      <div
                        class="mb-1"
                      >
                        <label
                          for="nameseniorcall"
                          class="form-label"
                        >Researcher</label>
                        <v-select
                          v-model="filters['researcher']"
                          label="name"
                          :options="users"
                          :get-option-key="option => option.id"
                          @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                          @input="saveFilter($event, 'researcher')"
                        />
                        <hr>
                      </div>
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Host institution</label>
                        <v-select
                          v-model="filters['host']"
                          label="name"
                          :options="institutions"
                          :get-option-key="option => option.id"
                          @search="onSearch({ name: $event }, 'institutions/filter')"
                          @input="saveFilter($event, 'host')"
                        />
                      </div>
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Areas</label>
                        <v-select
                          v-model="filters['area']"
                          label="name"
                          :options="areas"
                          :get-option-key="option => option.id"
                          @input="saveFilter($event, 'area')"
                        />
                      </div>
                    </template>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Type of grant</label>
                      <v-select
                        v-model="filters['grant_type']"
                        label="name"
                        :options="grantTypes"
                        :get-option-key="option => option.id"
                        @search="onSearch({ name: $event }, 'typesResource/filter')"
                        @input="saveFilter($event, 'grant_type')"
                      />
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Type of personnel</label>
                      <v-select
                        v-model="filters['personnel_type']"
                        label="name"
                        :options="personnelTypes"
                        :get-option-key="option => option.id"
                        @search="onSearch({ name: $event }, 'typesPersonnel/filter')"
                        @input="saveFilter($event, 'personnel_type')"
                      />
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Type of funding</label>
                      <v-select
                        v-model="filters['funding_type']"
                        label="name"
                        :options="typesFunding"
                        :get-option-key="option => option.id"
                        @search="onSearch({ name: $event }, 'typesFunding/filter')"
                        @input="saveFilter($event, 'funding_type')"
                      />
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Status authorization</label>
                      <v-select
                        v-model="filters['status']"
                        label="name"
                        :options="statuses"
                        :get-option-key="option => option.id"
                        @input="saveFilter($event, 'status')"
                      />
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Firm/Funding agency</label>
                      <input
                        v-model="filters['funding_agency']"
                        type="text"
                        class="form-control"
                        @change="saveFilter({ customLabel: true, name: `Firm/Funding agency: ${$event.target.value}`, value: `${$event.target.value}` }, 'funding_agency')"
                      >
                      <!-- <v-select
                        label="name"
                        :options="fundingAgencies"
                        :get-option-key="option => option.id"
                        @search="onSearch({ name: $event }, 'fundingAgencies/filter')"
                        @input="saveFilter($event, 'funding_agency')"
                      /> -->
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Programme</label>
                      <input
                        v-model="filters['programme']"
                        type="text"
                        class="form-control"
                        @change="saveFilter({ customLabel: true, name: `Programme: ${$event.target.value}`, value: `${$event.target.value}` }, 'programme')"
                      >
                      <!-- <v-select
                        label="name"
                        :options="programmes"
                        :get-option-key="option => option.id"
                        @search="onSearch({ name: $event }, 'programmes/filter')"
                        @input="saveFilter($event, 'programme')"
                      /> -->
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Title of the research project/Full name (if personnel)</label>
                      <input
                        v-model="filters['title']"
                        type="text"
                        class="form-control"
                        @change="saveFilter({ customLabel: true, name: `Title project/Full name: ${$event.target.value}`, value: `${$event.target.value}` }, 'title')"
                      >
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >People hired with this grant</label>
                      <select
                        v-model="filters['hired']"
                        class="form-select select2"
                        @input="saveFilter({customLabel: true, name: 'People hired: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value}, 'hired')"
                      >
                        <option value="">
                          All
                        </option>
                        <option value="true">
                          Yes
                        </option>
                        <option value="false">
                          No
                        </option>
                      </select>
                    </div>
                    <hr>
                    <p><strong>Filter by date</strong></p>
                    <div
                      v-if="!loadingFilter"
                      class="mb-1"
                    >
                      <label
                        for=""
                        class="form-label"
                      >From</label>
                      <br>
                      <date-picker
                        v-model="filters['begin_date']"
                        format="YYYY-MM-DD"
                        class="w-100"
                        value-type="format"
                        @change="saveFilter({ customLabel: true, name: `Begin date: ${$event}`, value: `${$event}` }, 'begin_date')"
                      />
                    </div>
                    <div
                      v-if="!loadingFilter"
                      class="mb-1"
                    >
                      <label
                        for=""
                        class="form-label"
                      >To</label>
                      <br>
                      <date-picker
                        v-model="filters['end_date']"
                        format="YYYY-MM-DD"
                        class="w-100"
                        value-type="format"
                        @change="saveFilter({ customLabel: true, name: `End date: ${$event}`, value: `${$event}` }, 'end_date')"
                      />
                    </div>
                    <div class="mb-1">
                      <div class="form-check form-check-inline">
                        <input
                          id="filterByDates1"
                          class="form-check-input"
                          type="radio"
                          name="filterByDates"
                          value="in_period"
                          checked="checked"
                          @change="saveFilter($event.target.value, 'check_dates')"
                        >
                        <label
                          class="form-check-label"
                          for="filterByDates1"
                        >In period</label>
                      </div>
                    </div>
                    <div class="mb-1">
                      <div class="form-check form-check-inline">
                        <input
                          id="filterByDates2"
                          class="form-check-input"
                          type="radio"
                          name="filterByDates"
                          value="begin_in"
                          @change="saveFilter($event.target.value, 'check_dates')"
                        >
                        <label
                          class="form-check-label"
                          for="filterByDates2"
                        >Begin in period</label>
                      </div>
                    </div>
                    <div class="mb-1">
                      <div class="form-check form-check-inline">
                        <input
                          id="filterByDates3"
                          class="form-check-input"
                          type="radio"
                          name="filterByDates"
                          value="end_in"
                          @change="saveFilter($event.target.value, 'check_dates')"
                        >
                        <label
                          class="form-check-label"
                          for="filterByDates3"
                        >End in period</label>
                      </div>
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Active </label>
                      <span
                        v-if="filters.begin_date || filters.end_date"
                        :id="`info-no-available`"
                      >  <i data-feather="info" /></span>
                      <b-tooltip
                        triggers="hover"
                        :target="`info-no-available`"
                      >
                        <span>You can only use this filter if it is not filtered by date</span>
                      </b-tooltip>
                      <select
                        v-model="filters['active']"
                        class="form-select select2"
                        :disabled="filters.begin_date || filters.end_date"
                        @input="saveFilter({customLabel: true, name: 'Active: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value}, 'active')"
                      >
                        <option
                          value=""
                          :selected="filters.begin_date || filters.end_date"
                        >
                          All
                        </option>
                        <option
                          value="true"
                        >
                          Yes
                        </option>
                        <option value="false">
                          No
                        </option>
                      </select>
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >ICREA active</label>
                      <select
                        v-model="filters['researcher_active']"
                        class="form-select"
                        @input="saveFilter({customLabel: true, name: 'ICREA active: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value}, 'researcher_active')"
                      >
                        <option value="">
                          All
                        </option>
                        <option value="true">
                          Yes
                        </option>
                        <option value="false">
                          No
                        </option>
                      </select>
                    </div>
                    <hr>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Total amount</label>
                      <select
                        v-model="filters['total_amount']"
                        class="form-select select2"
                        @input="saveFilter({ customLabel: true, name: `Total amount: ${$event.target.value}`, value: `${$event.target.value}` }, 'total_amount')"
                      >
                        <option
                          value=""
                          selected
                        >
                          All
                        </option>
                        <option value="0-100">
                          0€ - 100.000€
                        </option>
                        <option value="100-250">
                          101.001€ - 250.000€
                        </option>
                        <option value="250-500">
                          250.001€ - 500.000€
                        </option>
                        <option value="500-1000">
                          500.001€ - 1.000.000€
                        </option>
                        <option value="1000-2000">
                          1.000.001€ - 2.000.000€
                        </option>
                        <option value="2000-">
                          +2.000.000€
                        </option>
                      </select>
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Total amount of the grant given to your institution</label>
                      <select
                        v-model="filters['total_amount_institution']"
                        class="form-select select2"
                        @input="saveFilter({ customLabel: true, name: `Total amount institution: ${$event.target.value}`, value: `${$event.target.value}` }, 'total_amount_institution')"
                      >
                        <option
                          value=""
                          selected
                        >
                          All
                        </option>
                        <option value="0-100">
                          0€ - 100.000€
                        </option>
                        <option value="100-250">
                          101.001€ - 250.000€
                        </option>
                        <option value="250-500">
                          250.001€ - 500.000€
                        </option>
                        <option value="500-1000">
                          500.001€ - 1.000.000€
                        </option>
                        <option value="1000-2000">
                          1.000.001€ - 2.000.000€
                        </option>
                        <option value="2000-">
                          +2.000.000€
                        </option>
                      </select>
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Total amount to your Group/Research Unit</label>
                      <select
                        v-model="filters['total_amount_group']"
                        class="form-select select2"
                        @input="saveFilter({ customLabel: true, name: `Total amount group: ${$event.target.value}`, value: `${$event.target.value}` }, 'total_amount_group')"
                      >
                        <option
                          value=""
                          selected
                        >
                          All
                        </option>
                        <option value="0-100">
                          0€ - 100.000€
                        </option>
                        <option value="100-250">
                          101.001€ - 250.000€
                        </option>
                        <option value="250-500">
                          250.001€ - 500.000€
                        </option>
                        <option value="500-1000">
                          500.001€ - 1.000.000€
                        </option>
                        <option value="1000-2000">
                          1.000.001€ - 2.000.000€
                        </option>
                        <option value="2000-">
                          +2.000.000€
                        </option>
                      </select>
                    </div>
                  </template>
                </CompleteTable>
              </div>
            </div>
          </b-tab>
          <b-tab
            :active="$route.query.tab == 2"
            @click="loadTab(2)"
          >
            <template #title>
              Host support
            </template>
            <div class="row">
              <div
                v-if="!loading"
                class="col-12"
              >
                <CompleteTable
                  :sorted-field="sortedField"
                  :data="items"
                  :sort-mapping="sortMappingHosts"
                  :title="'hosts support'"
                  :total="itemsTotal"
                  :fetch-path="'grants/fetch'"
                  :filter-path="'grants/filter'"
                  :export-path="'grants/export'"
                >
                  <template v-slot="{field }">
                    <td
                      class="text-end"
                    >
                      <b-dropdown
                        variant="link"
                        no-caret
                        toggle-class="p-0"
                        right
                      >
                        <template #button-content>
                          <i data-feather="more-vertical" />
                        </template>
                        <div
                          class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                        >
                          <b-dropdown-item
                            v-if="loggedUser.roles.includes('super-admin')"
                            @click="$router.push({name: 'researcher.dashboard', params: { id: field.user.id }})"
                          >
                            <i
                              class="me-50"
                              data-feather="user"
                            /> View researcher profile
                          </b-dropdown-item>
                          <div
                            v-if="loggedUser.roles.includes('super-admin')"
                            class="dropdown-divider"
                          />
                          <b-dropdown-item @click="$router.push({name: 'researcher.grants.edit', query: { tab: 2, actAs: $route.query.actAs }, params: { id: field.id }})">
                            <i
                              class="me-50"
                              data-feather="edit-3"
                            /> Edit
                          </b-dropdown-item>
                          <b-dropdown-item
                            :href="$router.resolve({name: 'researcher.grants.edit', params: { id: field.id }, query: { tab: 2, actAs: $route.query.actAs }}).href"
                            target="_blank"
                          >
                            <i data-feather="external-link" />
                            Open in new tab
                          </b-dropdown-item>
                          <b-dropdown-item @click="showDeleteModal(field.id)">
                            <i
                              class="me-50"
                              data-feather="trash-2"
                            /> Delete <span class="float-end"><i data-feather="lock" /></span>
                          </b-dropdown-item>
                        </div>
                      </b-dropdown>
                      <a
                        href=""
                        class="btn btn-icon btn-light-secondary d-block d-sm-none"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasMenu"
                        aria-controls="offcanvasBottom"
                      ><i
                        data-feather="more-vertical"
                      /></a>
                    </td>
                  </template>
                  <template #customfilters>
                    <div
                      v-if="hasAdminPermission('view grants')"
                      class="mb-1"
                    >
                      <label
                        for="nameseniorcall"
                        class="form-label"
                      >Researcher</label>
                      <v-select
                        v-model="filters.researcher"
                        label="name"
                        :options="users"
                        :get-option-key="option => option.id"
                        @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                        @input="saveFilter($event, 'researcher')"
                      />
                      <hr>
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Type host support</label>
                      <v-select
                        v-model="filters.grant_type"
                        label="name"
                        :options="grantTypes"
                        :get-option-key="option => option.id"
                        @search="onSearch({ name: $event }, 'typesResource/filter')"
                        @input="saveFilter($event, 'grant_type')"
                      />
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Host institution</label>
                      <v-select
                        v-model="filters.host"
                        label="name"
                        :options="institutions"
                        :get-option-key="option => option.id"
                        @search="onSearch({ name: $event }, 'institutions/filter')"
                        @input="saveFilter($event, 'host')"
                      />
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Concept</label>
                      <select
                        v-model="filters.concept"
                        class="form-select select2"
                        @input="saveFilter({customLabel: true, name: 'Concept: ' + ($event.target.value), value: $event.target.value}, 'concept')"
                      >
                        <option
                          value=""
                          selected
                        >
                          All
                        </option>
                        <option value="Startup">
                          Startup
                        </option>
                        <option value="Base">
                          Base
                        </option>
                      </select>
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Full name</label>
                      <input
                        v-model="filters.full_name"
                        type="text"
                        class="form-control"
                        @change="saveFilter({customLabel: true, name: 'Full name: ' + ($event.target.value), value: $event.target.value}, 'full_name')"
                      >
                    </div>
                    <hr>
                    <p><strong>Filter by date</strong></p>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >From</label>
                      <br>
                      <date-picker
                        v-model="filters['begin_date']"
                        format="YYYY-MM-DD"
                        class="w-100"
                        value-type="format"
                        @change="saveFilter({customLabel: true, name: 'Begin date: ' + ($event), value: $event}, 'begin_date')"
                      />
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >To</label>
                      <br>
                      <date-picker
                        v-model="filters['end_date']"
                        format="YYYY-MM-DD"
                        class="w-100"
                        value-type="format"
                        @change="saveFilter({customLabel: true, name: 'End date: ' + ($event), value: $event}, 'end_date')"
                      />
                    </div>
                    <div class="mb-1">
                      <div class="form-check form-check-inline">
                        <input
                          id="filterByDates1"
                          class="form-check-input"
                          type="radio"
                          name="filterByDates"
                          value="in_period"
                          checked="checked"
                          @change="saveFilter($event.target.value, 'check_dates')"
                        >
                        <label
                          class="form-check-label"
                          for="filterByDates1"
                        >In period</label>
                      </div>
                    </div>
                    <div class="mb-1">
                      <div class="form-check form-check-inline">
                        <input
                          id="filterByDates2"
                          class="form-check-input"
                          type="radio"
                          name="filterByDates"
                          value="begin_in"
                          @change="saveFilter($event.target.value, 'check_dates')"
                        >
                        <label
                          class="form-check-label"
                          for="filterByDates2"
                        >Begin in period</label>
                      </div>
                    </div>
                    <div class="mb-1">
                      <div class="form-check form-check-inline">
                        <input
                          id="filterByDates3"
                          class="form-check-input"
                          type="radio"
                          name="filterByDates"
                          value="end_in"
                          @change="saveFilter($event.target.value, 'check_dates')"
                        >
                        <label
                          class="form-check-label"
                          for="filterByDates3"
                        >End in period</label>
                      </div>
                    </div>
                    <hr>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Active</label>
                      <span
                        v-if="filters.begin_date || filters.end_date"
                        :id="`info-no-available-host`"
                      >  <i data-feather="info" /></span>
                      <b-tooltip
                        triggers="hover"
                        :target="`info-no-available-host`"
                      >
                        <span>You can only use this filter if it is not filtered by date</span>
                      </b-tooltip>
                      <select
                        v-model="filters.active"

                        class="form-select select2"
                        :disabled="filters.begin_date || filters.end_date"
                        @input="saveFilter({customLabel: true, name: 'Active: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value}, 'active')"
                      >
                        <option
                          value=""
                          :selected="filters.begin_date || filters.end_date"
                        >
                          All
                        </option>
                        <option
                          value="true"
                          selected
                        >
                          Yes
                        </option>
                        <option value="false">
                          No
                        </option>
                      </select>
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >ICREA active</label>
                      <select
                        v-model="filters.researcher_active"
                        class="form-select"
                        @input="saveFilter({customLabel: true, name: 'Icrea active: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value}, 'researcher_active')"
                      >
                        <option value="">
                          All
                        </option>
                        <option value="true">
                          Yes
                        </option>
                        <option value="false">
                          No
                        </option>
                      </select>
                    </div>
                  </template>
                </CompleteTable>
              </div>
            </div>
          </b-tab>
        </b-tabs>
        <div
          v-if="loading"
          id="loading-bg"
        >
          <div
            class="loading"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
        </div>
      </div>
    </div>
    <TableColumns />
  </div>
<!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import { mapGetters } from 'vuex'
import {
  BDropdown, BDropdownItem, BTab, BTabs, BTooltip,
} from 'bootstrap-vue'
import TableColumns from '../../partials/offcanvas/TableColumns.vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import GrantStats from './components/GrantStats.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    TableColumns,
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    BTab,
    BTabs,
    BTooltip,
    GrantStats,
    DatePicker,
  },
  data() {
    return {
      loadingFilter: false,
      sortedField: 'Researcher',
      defaultFieldsGrants: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Position',
          checked: true,
          order: 2,
        },
        {
          name: 'Type of grant',
          checked: true,
          order: 3,
        },
        {
          name: 'Type of funding',
          checked: true,
          order: 4,
        },
        {
          name: 'Firm/Funding Agency',
          checked: true,
          order: 5,
        },
        {
          name: 'Programme',
          checked: true,
          order: 6,
        },
        {
          name: 'Tor/fn',
          checked: true,
          order: 7,
          label: 'title of research project/full name',
        },
        {
          name: 'People',
          checked: true,
          order: 8,
          label: 'personnel',
        },
        {
          name: 'Top',
          checked: true,
          order: 9,
          label: 'Type of personnel',
        },
        {
          name: 'Institution',
          checked: false,
          order: 10,
        },
        {
          name: 'Ta',
          checked: true,
          order: 11,
          label: 'Total amount of the grant',
        },
        {
          name: 'Tagi',
          checked: true,
          order: 12,
          label: 'total amount given to your institution',
        },
        {
          name: 'Tagru',
          checked: true,
          order: 13,
          label: 'total amount given to your group',
        },
        {
          name: 'Amount this year',
          checked: false,
          order: 14,
        },
        {
          name: 'Begin date',
          checked: true,
          order: 14,
        },
        {
          name: 'End date',
          checked: true,
          order: 15,
        },
        {
          name: 'Status authorization',
          checked: false,
          order: 16,
        },
        {
          name: 'Active',
          checked: false,
          order: 17,
        },
      ],
      defaultFieldsHosts: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Type host support',
          checked: true,
          order: 2,
        },
        {
          name: 'Host institution',
          checked: true,
          order: 3,
        },
        {
          name: 'Type of personnel',
          checked: true,
          order: 4,
        },
        {
          name: 'Full name',
          checked: true,
          order: 5,
        },
        {
          name: 'Amount',
          checked: true,
          order: 6,
        },
        {
          name: 'Begin date',
          checked: true,
          order: 7,
        },
        {
          name: 'End date',
          checked: true,
          order: 8,
        },
        {
          name: 'Active',
          checked: true,
          order: 9,
        },
      ],
      sortMappingHosts: {
        'Type host support': 'type.name',
        Researcher: 'user.name',
        'Host institution': 'host.name',
        'Type of personnel': 'personnels_yearly',
        'Full name': 'personnels',
        Amount: 'total_amount',
        'Begin date': 'begin_date',
        'End date': 'end_date',
        Active: 'active',
      },
      sortMappingGrants: {
        'Type of grant': 'type.name',
        Researcher: 'user.name',
        Position: 'position_table',
        Top: 'personnels_yearly',
        Institution: 'host.name',
        Area: 'areas',
        'Type of funding': 'funding_type.name',
        'Firm/Funding Agency': 'funding_agency_manual',
        Programme: 'programme_manual',
        'Tor/fn': 'title_table',
        People: 'personnels_yearly.length',
        Ta: 'total_amount',
        Tagi: 'total_amount_institution',
        Tagru: 'total_amount_group',
        'Amount this year': 'amount_assigned',
        'Begin date': 'begin_date',
        'End date': 'end_date',
        'Status authorization': 'status.status_table',
        Active: 'active',
      },
      realFields: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'grants/items',
      itemsTotal: 'grants/itemsTotal',
      fields: 'modals/tableSettingsFields',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      filters: 'filters/filters',
      /* Filters */
      grantTypes: 'typesResource/items',
      personnelTypes: 'typesPersonnel/items',
      typesFunding: 'typesFunding/items',
      fundingAgencies: 'fundingAgencies/items',
      programmes: 'programmes/items',
      institutions: 'institutions/institutions',
      areas: 'areas/areas',
      statuses: 'status/statuses',
    }),
  },
  async mounted() {
    if (this.$route.query.user_id) {
      await this.saveFilter({ id: this.$route.query.user_id }, 'researcher')
    }
    // await this.$store.dispatch('filters/saveFilters', { value: `${new Date().getFullYear()}-01-01`, field: 'begin_date' })
    // await this.$store.dispatch('filters/saveFilters', { value: `${new Date().getFullYear()}-12-31`, field: 'end_date' })
    // await this.$store.dispatch('filters/saveFilters', { value: { name: 'Active', value: true }, field: 'active' })
    this.saveFilter({ customLabel: true, name: `Begin date: ${new Date().getFullYear()}-01-01`, value: `${new Date().getFullYear()}-01-01` }, 'begin_date')
    this.saveFilter({ customLabel: true, name: `End date: ${new Date().getFullYear()}-12-31`, value: `${new Date().getFullYear()}-12-31` }, 'end_date')
    this.loadTab(this.$route.query.tab != 2 ? 1 : 2)
    // this.$store.dispatch('grants/stats')

    this.$store.dispatch('status/filterStatus', 'Types of funding')

    if (this.institutions.length === 0) {
      this.$store.dispatch('institutions/filter')
    }

    if (this.areas.length === 0) {
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async loadTab(table) {
      this.loading = true
      this.$store.dispatch('filters/clearFilters')

      this.saveFilter({ name: table === 1 ? 'Grants & Contracted Research' : 'Host support', value: table }, 'type')

      if (table === 1) {
        await this.$store.dispatch('modals/fetchUserFields', 'grants-contracteds')
        if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
          await this.$store.dispatch('modals/fetchFields', {
            fields: this.defaultFieldsGrants,
            table: 'grants-contracteds',
          })
        }
      } else {
        await this.$store.dispatch('modals/fetchUserFields', 'grants-host')
        if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
          await this.$store.dispatch('modals/fetchFields', {
            fields: this.defaultFieldsHosts,
            table: 'grants-host',
          })
        }
      }

      if (this.fields) {
        this.realFields = Object.keys(this.fields).map(key => this.fields[key])
      }

      await this.$store.dispatch('grants/filter', this.filters)

      feather.replace({
        width: 14,
        height: 14,
      })

      this.loading = false
    },
    async appliedFilters(applied) {
      if (applied && (this.filters.begin_date || this.filters.end_date)) {
        this.realFields[this.realFields.findIndex(f => f.name === 'Amount this year')].checked = false
        await this.$store.dispatch('modals/fetchAuxFields', this.realFields)
      }

      if (applied && (this.filters.begin_date || this.filters.end_date) && this.filters.active) {
        const mainFilter = JSON.parse(localStorage.getItem('filters'))
        delete mainFilter['researcher.grants.index'].active
        localStorage.setItem('filters', JSON.stringify(mainFilter))
        this.loadTab(this.$route.query.tab != 2 ? 1 : 2)
      }
    },
    openTableSettings() {
      this.$store.dispatch('modals/toggleTableSettings', true)
    },
    saveFilter(value, field) {
      this.checkActiveWIthDatesFilter(field)
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
      this.loadingFilter = true
      setTimeout(() => {
        this.loadingFilter = false
      }, 10)
    },
    checkActiveWIthDatesFilter(from) {
      if ((from === 'begin_date' || from === 'end_date') && this.filters.active) {
        const mainFilter = JSON.parse(localStorage.getItem('filters'))
        delete mainFilter['researcher.grants.index'].active
        localStorage.setItem('filters', JSON.stringify(mainFilter))
      }
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '' && search.name.length > 3) {
        await this.$store.dispatch(store, search)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'grants/fetch', url: `${Vue.prototype.$groupUrl}/grants/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
